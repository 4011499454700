@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.error-message {
  color: $color-carmo-red;
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
}
