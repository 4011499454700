@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.private-site {
  &__title {
    &.container {
      padding-left: 0;
      padding-right: 0;
    }

    & h1 {
      padding: 16px 0;
      text-align: center;
      background-color: $color-carmo-main;
      @include font-headline-medium();
    }
  }
}

.backward-link {
  text-align: center;
  margin-top: 30px;
  padding: 16px 0;
  background-color: $color-carmo-middle-gray;

  & a {
    display: inline-block;
    color: #000;
    background: #fff;
    border: none;
    border-radius: 50px;
    padding: 8px 16px 8px 36px;
    text-align: center;
    @include font-body-mini-bold();
    @include link-arrow-left();
  }
}
