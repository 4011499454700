.headline {
  background-color: $color-carmo-gray;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  text-align: center;
  @include font-body();
}

.row {
  &__page-description {
    margin-bottom: 20px;
    @include font-body();

    & p {
      margin-bottom: 10px;
    }
  }

  &__link {
    @include font-annotation();
    & a {
      color: $color-link;
    }
  }

  &__submit-button {
    text-align: center;
    margin-top: 30px;
  }

  &__link-button {
    text-align: center;
    margin-top: 30px;

    & a {
      display: inline-block;
      color: #000;
      background: #fff;
      border: 1px solid #000;
      border-radius: 50px;
      padding: 8px 16px 8px 36px;
      text-align: center;
      @include font-body-mini-bold();
      @include link-arrow-left();
    }
  }

  &__form {
    margin-bottom: 10px;
    & .form-group {
      margin-bottom: 10px;

      & .form-label {
        @include font-headline-small();
      }

      & .form-control {
        background-color: #fff;
        border: 1px solid $color-carmo-middle-gray;
        &.is-invalid {
          background-color: $color-carmo-light-red;
          border: 1px solid $color-carmo-red;
        }
      }

      & .invalid-feedback {
        color: $color-carmo-red;
      }
    }
  }
}
