@import 'contents/styles/color';
@import 'contents/styles/font';
@import 'contents/styles/mixin';

.header {
  &__backward {
    & p {
      position: relative;
      color: #000;
      text-decoration: none;
      margin-left: 10px;
      padding-left: 16px;
      @include font(16px, 300);
      &::before {
        display: block;
        content: '';
        position: absolute;
        top: calc(50% - 7px);
        left: 0;
        width: 9px;
        height: 14px;
        transform: rotate(180deg);
        background: no-repeat url('/contents/images/icon/vector.svg');
      }
    }
  }

  &__logo {
    & img {
      width: 180px;
      height: auto;
    }
  }

  &__toggle {
    button {
      &.navbar-toggler {
        box-shadow: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.global-menu {
  &__header {
    background-color: $color-carmo-main;
  }

  &__list {
    & .list-group-item {
      @include link-arrow-right();
      @include font-body();
      padding: 8px;
      border: none;
      border-bottom: 1px dashed $color-carmo-middle-gray;
    }
  }
}
